<template>
  <!-- REGISTER MODE: Show radius input & Save button -->
  <div v-if="register && currentLocation" class="w-96 max-w-full flex flex-col justify-center">
    <input
      v-model.number="currentLocation.radius"
      class="w-full text-center border border-gray-400 mt-5"
      type="number"
      @input="updateFormDataRadius"
    />
    <p class="text-center mt-3">
      {{ t('companyArea.components.map.radiusText1') }} <br/>
      {{ t('companyArea.components.map.radiusText2') }}
    </p>

    <div v-if="currentRadius > 0" class="flex justify-center gap-3 mt-3 mb-3">
      <button class="btn btn-lg btn-secondary" @click="recalculateChosen">{{ t('companyArea.components.map.recalculate') || 'Recalculate' }}</button>
      <button class="btn btn-lg btn-primary" @click="storeArea('all')">{{ t('general.next') }}</button>
    </div>
  </div>

  <div v-if="unsaved && !register" class="flex justify-center mt-3">
    <button :class="{ 'pointer-events-none':(!readyForSave)}"
            class="btn btn-lg btn-primary"
            @click="submitChanges"
    >
      {{ t('companyArea.components.map.saveChangesForClassification', { name: classification.name }) }}
    </button>
  </div>

  <div v-if="unsaved && !register" class="text-red-700 my-2">
    <span class="font-bold">{{ t('general.attention') }}</span>
    {{ t('companyArea.components.map.unsavedChanges') }}
  </div>

  <div :id="mapElementId" class="relative w-full" style="height: 500px;">
    <spinner v-if="!loaded || reload || isLoadingPostcodes"
             ref="reload"
             class="opacity-50"
             style="z-index: 10000;"
    />
  </div>

  <div class="grid place-content-end">
    <div class="basis-1">
      <div class="w-full">
        <div class="flex justify-between">
          <div class="text-xs mt-1">{{ t('companyArea.components.map.unprecise') }}</div>
          <div class="text-xs mt-1">{{ t('companyArea.components.map.precise') }}</div>
        </div>
      </div>
      <input v-model="precision"
             class="rounded-lg appearance-none bg-gray-200 h-1 w-full mt-0"
             data-cy="input-range-local"
             max="3"
             min="0"
             step="1"
             type="range"
      >
    </div>
  </div>

  <div v-if="unsaved && !register" class="flex justify-center mt-3">
    <button :class="{ 'pointer-events-none':(!readyForSave)}"
            class="btn btn-lg btn-primary"
            @click="submitChanges"
    >
      {{ t('companyArea.components.map.saveChangesForClassification', { name: classification.name }) }}
    </button>
  </div>

  <p v-if="!register && calc" class="text-xs text-gray-500 mt-4">
    {{ t('companyArea.components.map.postcodesInfo') }}
  </p>

  <!-- Beispiel der "PLZ 2er-Stellen" Buttons -->
  <div v-if="!register && calc" class="mt-4 border rounded-md p-2">
    <div class="flex">
      <div :class="{ 'bg-green-200':(activeZip?.includes(10))}" class="p-2 cursor-pointer" @click="recalculateArea(10)">10</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(11))}" class="p-2 cursor-pointer" @click="recalculateArea(11)">11</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(12))}" class="p-2 cursor-pointer" @click="recalculateArea(12)">12</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(13))}" class="p-2 cursor-pointer" @click="recalculateArea(13)">13</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(14))}" class="p-2 cursor-pointer" @click="recalculateArea(14)">14</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(15))}" class="p-2 cursor-pointer" @click="recalculateArea(15)">15</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(16))}" class="p-2 cursor-pointer" @click="recalculateArea(16)">16</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(17))}" class="p-2 cursor-pointer" @click="recalculateArea(17)">17</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(18))}" class="p-2 cursor-pointer" @click="recalculateArea(18)">18</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(19))}" class="p-2 cursor-pointer" @click="recalculateArea(19)">19</div>
    </div>
    <div class="flex">
      <div :class="{ 'bg-green-200':(activeZip?.includes(20))}" class="p-2 cursor-pointer" @click="recalculateArea(20)">20</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(21))}" class="p-2 cursor-pointer" @click="recalculateArea(21)">21</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(22))}" class="p-2 cursor-pointer" @click="recalculateArea(22)">22</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(23))}" class="p-2 cursor-pointer" @click="recalculateArea(23)">23</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(24))}" class="p-2 cursor-pointer" @click="recalculateArea(24)">24</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(25))}" class="p-2 cursor-pointer" @click="recalculateArea(25)">25</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(26))}" class="p-2 cursor-pointer" @click="recalculateArea(26)">26</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(27))}" class="p-2 cursor-pointer" @click="recalculateArea(27)">27</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(28))}" class="p-2 cursor-pointer" @click="recalculateArea(28)">28</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(29))}" class="p-2 cursor-pointer" @click="recalculateArea(29)">29</div>
    </div>
    <div class="flex">
      <div :class="{ 'bg-green-200':(activeZip?.includes(30))}" class="p-2 cursor-pointer" @click="recalculateArea(30)">30</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(31))}" class="p-2 cursor-pointer" @click="recalculateArea(31)">31</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(32))}" class="p-2 cursor-pointer" @click="recalculateArea(32)">32</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(33))}" class="p-2 cursor-pointer" @click="recalculateArea(33)">33</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(34))}" class="p-2 cursor-pointer" @click="recalculateArea(34)">34</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(35))}" class="p-2 cursor-pointer" @click="recalculateArea(35)">35</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(36))}" class="p-2 cursor-pointer" @click="recalculateArea(36)">36</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(37))}" class="p-2 cursor-pointer" @click="recalculateArea(37)">37</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(38))}" class="p-2 cursor-pointer" @click="recalculateArea(38)">38</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(39))}" class="p-2 cursor-pointer" @click="recalculateArea(39)">39</div>
    </div>
    <div class="flex">
      <div :class="{ 'bg-green-200':(activeZip?.includes(40))}" class="p-2 cursor-pointer" @click="recalculateArea(40)">40</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(41))}" class="p-2 cursor-pointer" @click="recalculateArea(41)">41</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(42))}" class="p-2 cursor-pointer" @click="recalculateArea(42)">42</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(43))}" class="p-2 cursor-pointer" @click="recalculateArea(43)">43</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(44))}" class="p-2 cursor-pointer" @click="recalculateArea(44)">44</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(45))}" class="p-2 cursor-pointer" @click="recalculateArea(45)">45</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(46))}" class="p-2 cursor-pointer" @click="recalculateArea(46)">46</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(47))}" class="p-2 cursor-pointer" @click="recalculateArea(47)">47</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(48))}" class="p-2 cursor-pointer" @click="recalculateArea(48)">48</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(49))}" class="p-2 cursor-pointer" @click="recalculateArea(49)">49</div>
    </div>
    <div class="flex">
      <div :class="{ 'bg-green-200':(activeZip?.includes(50))}" class="p-2 cursor-pointer" @click="recalculateArea(50)">50</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(51))}" class="p-2 cursor-pointer" @click="recalculateArea(51)">51</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(52))}" class="p-2 cursor-pointer" @click="recalculateArea(52)">52</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(53))}" class="p-2 cursor-pointer" @click="recalculateArea(53)">53</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(54))}" class="p-2 cursor-pointer" @click="recalculateArea(54)">54</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(55))}" class="p-2 cursor-pointer" @click="recalculateArea(55)">55</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(56))}" class="p-2 cursor-pointer" @click="recalculateArea(56)">56</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(57))}" class="p-2 cursor-pointer" @click="recalculateArea(57)">57</div>
    </div>
    <div class="flex">
      <div :class="{ 'bg-green-200':(activeZip?.includes(60))}" class="p-2 cursor-pointer" @click="recalculateArea(60)">60</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(61))}" class="p-2 cursor-pointer" @click="recalculateArea(61)">61</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(62))}" class="p-2 cursor-pointer" @click="recalculateArea(62)">62</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(63))}" class="p-2 cursor-pointer" @click="recalculateArea(63)">63</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(64))}" class="p-2 cursor-pointer" @click="recalculateArea(64)">64</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(65))}" class="p-2 cursor-pointer" @click="recalculateArea(65)">65</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(66))}" class="p-2 cursor-pointer" @click="recalculateArea(66)">66</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(67))}" class="p-2 cursor-pointer" @click="recalculateArea(67)">67</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(68))}" class="p-2 cursor-pointer" @click="recalculateArea(68)">68</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(69))}" class="p-2 cursor-pointer" @click="recalculateArea(69)">69</div>
    </div>
    <div class="flex">
      <div :class="{ 'bg-green-200':(activeZip?.includes(70))}" class="p-2 cursor-pointer" @click="recalculateArea(70)">70</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(71))}" class="p-2 cursor-pointer" @click="recalculateArea(71)">71</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(72))}" class="p-2 cursor-pointer" @click="recalculateArea(72)">72</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(73))}" class="p-2 cursor-pointer" @click="recalculateArea(73)">73</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(74))}" class="p-2 cursor-pointer" @click="recalculateArea(74)">74</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(75))}" class="p-2 cursor-pointer" @click="recalculateArea(75)">75</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(76))}" class="p-2 cursor-pointer" @click="recalculateArea(76)">76</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(77))}" class="p-2 cursor-pointer" @click="recalculateArea(77)">77</div>
    </div>
    <div class="flex">
      <div :class="{ 'bg-green-200':(activeZip?.includes(80))}" class="p-2 cursor-pointer" @click="recalculateArea(80)">80</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(81))}" class="p-2 cursor-pointer" @click="recalculateArea(81)">81</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(82))}" class="p-2 cursor-pointer" @click="recalculateArea(82)">82</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(83))}" class="p-2 cursor-pointer" @click="recalculateArea(83)">83</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(84))}" class="p-2 cursor-pointer" @click="recalculateArea(84)">84</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(85))}" class="p-2 cursor-pointer" @click="recalculateArea(85)">85</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(86))}" class="p-2 cursor-pointer" @click="recalculateArea(86)">86</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(87))}" class="p-2 cursor-pointer" @click="recalculateArea(87)">87</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(88))}" class="p-2 cursor-pointer" @click="recalculateArea(88)">88</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(89))}" class="p-2 cursor-pointer" @click="recalculateArea(89)">89</div>
    </div>
    <div class="flex">
      <div :class="{ 'bg-green-200':(activeZip?.includes(90))}" class="p-2 cursor-pointer" @click="recalculateArea(90)">90</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(91))}" class="p-2 cursor-pointer" @click="recalculateArea(91)">91</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(92))}" class="p-2 cursor-pointer" @click="recalculateArea(92)">92</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(93))}" class="p-2 cursor-pointer" @click="recalculateArea(93)">93</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(94))}" class="p-2 cursor-pointer" @click="recalculateArea(94)">94</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(95))}" class="p-2 cursor-pointer" @click="recalculateArea(95)">95</div>
      <div :class="{ 'bg-green-200':(activeZip?.includes(96))}" class="p-2 cursor-pointer" @click="recalculateArea(96)">96</div>
    </div>
  </div>

  <confirmation-modal v-if="modal" style="z-index: 1000;" @close="modal = false">
    <div class="text-center text-gray-900 text-lg font-bold">
      {{ t('companyArea.components.map.changeForAllClassificationsQuestion', { name: classification.name }) }}
    </div>
    <div class="flex mt-4 justify-center">
      <div class="px-3 py-2 border rounded-md mr-3 mb-3 bg-gray-100 hover:bg-gray-300 cursor-pointer"
           @click="storeArea('only')"
      >
        {{ t('companyArea.components.map.onlyClassification', { name: classification.name }) }}
      </div>
      <div class="px-3 py-2 border rounded-md mb-3 bg-gray-100 hover:bg-gray-300 cursor-pointer"
           @click="storeArea('all')"
      >
        {{ t('companyArea.components.map.forAll') }}
      </div>
    </div>
    <div v-if="classifications.some(obj => obj.radius !== null && obj.id !== classification.id)">
      <div class="flex mt-2 justify-center">
        <div class="px-3 py-2 border rounded-md mb-2 bg-gray-100 hover:bg-gray-300 cursor-pointer"
             @click="storeArea('standard')"
        >
          {{ t('companyArea.components.map.forAllExcept') }}
        </div>
      </div>
      <div class="flex justify-center">
        <div v-for="specialClassification in classifications.filter(obj => obj.radius !== null && obj.id !== classification.id)"
             :key="specialClassification.id"
             class=" text-xs px-2 py-1 border rounded-md mb-2 bg-gray-300"
        >
          {{ specialClassification.name }}
        </div>
      </div>
    </div>
  </confirmation-modal>
  <spinner v-if="waitForServerResponse || isSyncingPostcodes" :message="t('companyArea.components.map.canTakeAWhile')" style="z-index: 10000;"/>
</template>

<script setup>
  import L from 'leaflet';
  import 'leaflet/dist/leaflet.css';
  import 'leaflet/dist/leaflet.js';

  import { useQuery, useMutation } from '@tanstack/vue-query';
  import { createQueryFn, createMutationFn, companyApiVueQuery } from '@/api/api.js';
  import Spinner from '../../snippets/Spinner.vue';
  import ConfirmationModal from '../../snippets/ConfirmationModal.vue';
  import store from '../../store/mainStore';
  import markerIcon from 'leaflet/dist/images/marker-icon.png';
  import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
  import markerShadow from 'leaflet/dist/images/marker-shadow.png';
  import notification from '../../connections/notification';
  import { computed, onBeforeUnmount, onMounted, onUnmounted, ref, watch, nextTick } from 'vue';
  import { useI18n } from 'vue-i18n';
  const { t } = useI18n({ useScope: 'global' });

  // Define props
  const props = defineProps({
    register: { type: Boolean, default: false, },
    locations: { type: Array, required: true, },
    classification: { type: Object, required: true, },
    classifications: { type: Array, required: true, },
    company: { type: Object, required: true, },
    radiusFromDirectFilter: { type: Number, default: null, },
    calc: { type: Boolean, default: true, },
    showRadiusPopupForLocation: { type: Number, default: null, }
  });

  // Define emits
  const emit = defineEmits(['saved']);

  // Reactive state
  const zipPostcodes = ref([]);
  const radiusPostcodes = ref([]);
  const companyPostcodes = ref({});
  const postcodes = ref([]);
  const geoJson = ref([]);
  const map = ref(null);
  const layer = ref({});
  const radius = ref(10);
  const locationsRadius = ref([]);
  const locationsCloned = ref(JSON.parse(JSON.stringify(props.locations)));
  const activeZip = ref(props.classification.zip ? [...props.classification.zip] : []);
  const currentLocation = ref({});
  const loaded = ref(false);
  const reload = ref(false);
  const waitForServerResponse = ref(false);
  const readyForSave = ref(true);
  const unsaved = ref(false);
  const modal = ref(false);
  const precision = ref(0);
  const formdata = ref({
    radius: [],
  });
  const mapElementId = ref(`map-${Date.now()}-${Math.random().toString(36).substring(2, 9)}`);
  const mapInitializationInProgress = ref(false);
  const mapInitialized = ref(false);

  // Queries and Mutations
  // 1. Fetch postcodes and GeoJSON
  const postcodesAndGeoJsonQuery = useQuery({
    queryKey: ['postcodes-and-geojson', { precision: precision.value }],
    queryFn: createQueryFn('location', 'get', 'postcodes-and-geojson?precision=' + precision.value),
    enabled: false, // We'll trigger this manually
  });

  // 2. Fetch only GeoJSON for precision changes
  const geoJsonQuery = useQuery({
    queryKey: ['geojson', { precision: precision.value }],
    queryFn: createQueryFn('location', 'get', 'geojson?precision=' + precision.value),
    enabled: false, // We'll trigger this manually
  });

  // 3. Sync postcodes mutation
  const {
    isPending: isSyncingPostcodes,
    isError: syncPostcodesHasError,
    isSuccess: syncPostcodesIsSuccess,
    error: syncPostcodesError,
    mutate: syncPostcodesMutation,
  } = useMutation({
    mutationFn: (data) => createMutationFn('company', 'post', `/companies/${props.company.id}/postcodes/sync`,)(data),
    onSuccess: (response) => {
      if (response.notificationType === 'success') {
        store.commit('company/setInitialCompanyData', response.content.company);
        unsaved.value = false;
        emit('saved');
      } else {
        notifyValidationError(response.data.message);
      }
      modal.value = false;
      waitForServerResponse.value = false;
    },
    onError: (error) => {
      if (error.response?.data?.message) {
        notifyValidationError(error.response.data.message);
      }
      modal.value = false;
      waitForServerResponse.value = false;
    },
  });

  // Computed properties
  const currentRadius = computed(() => {
    const entry = formdata.value.radius.find(item => item.id === currentLocation.value.id);
    return entry ? entry.radius : null;
  });

  const saveBtnText = computed(() => t('general.show'));

  const isLoadingPostcodes = computed(() => {
    return postcodesAndGeoJsonQuery.isLoading.value || geoJsonQuery.isLoading.value;
  });

  // Helper functions
  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  // Event handlers and methods
  // Update the radius in formdata when changed via input
  function updateFormDataRadius() {
    if (!currentLocation.value) return;

    const radiusValue = currentLocation.value.radius;
    const index = formdata.value.radius.findIndex(item => item.id === currentLocation.value.id);

    if (index !== -1) {
      // Update existing entry
      formdata.value.radius[index].radius = radiusValue;
    } else {
      // Add new entry
      formdata.value.radius.push({ id: currentLocation.value.id, radius: radiusValue, });
    }

    // Redraw circle immediately to update visual
    const locationObj = locationsCloned.value.find(loc => loc.id === currentLocation.value.id);
    if (locationObj) {
      // Force circle removal first
      if (locationObj.mapCircle && map.value) {
        try {
          if (map.value.hasLayer(locationObj.mapCircle)) {
            locationObj.mapCircle.removeFrom(map.value);
          }
        } catch (e) {
          console.warn(`Error removing circle during updateFormDataRadius: ${e.message}`);
        }
        locationObj.mapCircle = null;
      }

      // Then redraw
      drawCircle(locationObj);
    }

    // Mark as unsaved
    unsaved.value = true;
    readyForSave.value = false;
  }

  function recalculateChosen() {
    if (!currentLocation.value) return;

    // Show loading state
    reload.value = true;
    readyForSave.value = false;

    // Run recalculation
    recalculateArea(null).then(result => {
      if (result === true) {
        reload.value = false;
        readyForSave.value = true;
      } else {
        reload.value = false;
        readyForSave.value = true;
      }
    }).catch(error => {
      console.error('Error in recalculation:', error);
      notification.error(t('companyArea.components.map.recalculateError'));
      reload.value = false;
      readyForSave.value = true;
    });
  }

  function submitChanges() {
    if (props.classifications.length > 1) {
      modal.value = true;
    } else {
      storeArea('all');
    }
  }

  function storeArea(forAllClassifications) {
    modal.value = false;
    if (readyForSave.value === false) {
      recalculateChosen();
    }

    waitForServerResponse.value = true;
    // Object -> Array
    const companyPostcodesArray = Object.keys(companyPostcodes.value).map(key => [companyPostcodes.value[key]]);

    // Prepare data for mutation
    const mutationData = {
      companyPostcodes: companyPostcodesArray,
      activeZip: activeZip.value,
      classification: props.classification,
      forAllClassifications: forAllClassifications,
      radius: formdata.value.radius,
    };

    // Execute mutation
    syncPostcodesMutation(mutationData);
  }

  function notifyValidationError(validationMessage) {
    try {
      notification.error(validationMessage.replace(/Radius\.0\.radius/g, 'Radius'));
    } catch (error) {
      notification.error('Es ist ein Fehler aufgetreten');
    }
  }

  // Fetch data and initialize map
  async function refreshData() {
    try {
      // Make sure the map element exists before proceeding
      await nextTick();

      const result = await postcodesAndGeoJsonQuery.refetch();
      if (result.isSuccess && result.data) {
        // Clear any existing data first
        postcodes.value = [];
        geoJson.value = [];

        // Wait for the DOM to update
        await nextTick();

        // Now assign the new data
        postcodes.value = result.data[0];
        geoJson.value = result.data[1];

        // Initialize the map after data is ready
        await nextTick();
        initMapAfterDataLoad();
        return true;
      } else {
        console.error("Failed to fetch postcodes and GeoJSON");
        notification.error('Failed to load map data');
        return false;
      }
    } catch (error) {
      console.error("Error refreshing data:", error);
      notification.error('Failed to load map data');
      return false;
    }
  }

  // Initialize the map after data is loaded
  function initMapAfterDataLoad() {
    // Wait longer before map initialization to ensure DOM is ready
    setTimeout(async () => {
      try {
        // Check if the map element exists in the DOM
        const mapElement = document.getElementById(mapElementId.value);
        if (!mapElement) {
          console.error(`Map element with ID ${mapElementId.value} not found`);
          // Generate a new ID and try again
          mapElementId.value = `map-${Date.now()}-${Math.random().toString(36).substring(2, 9)}`;
          await nextTick();
          setTimeout(() => initMapAfterDataLoad(), 100);
          return;
        }

        const success = await initMap();
        if (success && map.value) {
          // Wait for map to be fully initialized
          setTimeout(() => {
            // Draw all markers and circles
            if (map.value) {
              locationsCloned.value.forEach(obj => {
                drawCircle(obj);
                drawMarker(obj);
              });
            }
          }, 200);
        } else {
          console.warn('Map initialization failed');
        }
      } catch (error) {
        console.error("Error in map initialization:", error);
      }
    }, 300); // Increased timeout to ensure DOM is ready
  }

  // Safely destroy the map instance
  function destroyMap() {
    if (map.value) {
      try {
        // Remove all layers first
        map.value.eachLayer((layer) => {
          if (layer.remove) {
            try {
              layer.remove();
            } catch (e) {
              console.warn('Error removing layer:', e);
            }
          }
        });

        // Remove all event listeners
        map.value.off();

        // Remove the map
        map.value.remove();
      } catch (e) {
        console.warn('Error during map destruction:', e);
      }

      // Clear the reference regardless of success
      map.value = null;
      mapInitialized.value = false;
    }
  }

  /**
   * Initialize the map with improved error handling
   */
  async function initMap() {

    // Prevent concurrent initialization
    if (mapInitializationInProgress.value) {
      return false;
    }

    mapInitializationInProgress.value = true;

    try {
      // First, make sure any existing map is fully destroyed
      destroyMap();

      // Verify the map element exists before trying to initialize
      const mapElement = document.getElementById(mapElementId.value);
      if (!mapElement) {
        console.error(`Map element with ID ${mapElementId.value} not found in DOM`);
        mapInitializationInProgress.value = false;
        return false;
      }

      // Wait for any pending DOM updates
      await nextTick();

      // Create a fresh map instance immediately
      map.value = L.map(mapElementId.value, {
        // Disable features that can cause null map errors during initialization
        fadeAnimation: false,
        zoomAnimation: false,
        inertia: false,
        // Set initial view with disabled animations
        animate: false
      });

      // Set view separately after map is initialized
      map.value.setView([46.8, 8.23], 8, { animate: false });

      // Add tile layer right away
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '© OpenStreetMap contributors',
      }).addTo(map.value);

      // Set loaded flag early to show map
      loaded.value = true;

      // Filter postcodes based on active zip codes
      zipPostcodes.value = postcodes.value.filter(postalCode =>
        activeZip.value?.some(button => postalCode.zip.startsWith(button)),
      );

      // Only attempt recalculation if we have postcodes
      if (postcodes.value.length > 0) {
        // Draw markers and circles before recalculation for immediate visual feedback
        locationsCloned.value.forEach(obj => {
          drawCircle(obj);
          drawMarker(obj);
        });

        try {
          // Then recalculate areas (which will show progressive loading)
          let result = await recalculateArea(null, true);
          if (result) {
            readyForSave.value = true;

            // Bring circles to front after all areas are loaded
            if (map.value) {
              locationsCloned.value.forEach(obj => {
                if (obj.mapCircle) obj.mapCircle.bringToFront();
              });
            }
          }
        } catch (error) {
          console.error("Error during area recalculation:", error);
        }
      } else {
        console.warn('No postcodes available for map initialization');
      }

      // Enable animations after initialization is complete
      if (map.value) {
        setTimeout(() => {
          try {
            map.value.options.fadeAnimation = true;
            map.value.options.zoomAnimation = true;
            map.value.options.inertia = true;
          } catch (e) {
            console.warn("Error enabling animations:", e);
          }
        }, 1000);
      }

      mapInitialized.value = true;
      mapInitializationInProgress.value = false;
      return true;
    } catch (e) {
      console.error('Error initializing map:', e);
      loaded.value = true; // Set loaded to true to avoid indefinite loading state
      mapInitialized.value = false;
      mapInitializationInProgress.value = false;
      return false;
    }
  }

  /**
   * Draw a marker on the map for a location
   */
  function drawMarker(location) {
    // Check if map exists and is initialized
    if (!map.value) {
      console.warn('Cannot draw marker: map is not initialized');
      return;
    }

    try {
      // First, find the location in our cloned array to ensure we operate on the right object
      const locationIndex = locationsCloned.value.findIndex(loc => loc.id === location.id);
      if (locationIndex < 0) {
        console.warn(`Location with ID ${location.id} not found in locationsCloned`);
        return;
      }

      // Get reference to the actual stored location object (this is important)
      const locationObj = locationsCloned.value[locationIndex];

      // Remove any existing marker
      if (locationObj.mapMarker) {
        try {
          if (map.value && map.value.hasLayer(locationObj.mapMarker)) {
            locationObj.mapMarker.removeFrom(map.value);
          }
        } catch (e) {
          console.warn(`Error removing marker: ${e.message}`);
        }
        locationObj.mapMarker = null;
      }

      let currentPostcode = postcodes.value.find(obj => obj.id === locationObj.postcode_id);
      if (!currentPostcode) {
        console.warn(`Postcode not found for location ID: ${locationObj.id}`);
        return;
      }

      const markerIconDefault = L.icon({
        iconUrl: markerIcon,
        iconRetinaUrl: markerIcon2x,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        tooltipAnchor: [16, -28],
        shadowUrl: markerShadow,
        shadowSize: [41, 41],
        shadowAnchor: [12, 41],
      });

      const marker = L.marker(
        [currentPostcode.latitude, currentPostcode.longitude],
        { icon: markerIconDefault }
      );

      // Add to map and store reference in location object
      marker.addTo(map.value);
      locationObj.mapMarker = marker;

      const width = 90 + (saveBtnText.value.length * 7);
      const popup = L.popup().setContent(`
      <div style="width: ${width}px; max-width:200px">
        <div class="mt-2 flex rounded-md shadow-sm">
          <div class="relative flex flex-grow items-stretch focus-within:z-10">
            <input type="string" name="radius" id="inputField${locationObj.id}"
                   class="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-2 text-gray-900 ring-1
                          ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                          focus:ring-indigo-600 sm:text-sm sm:leading-6">
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <span class="text-gray-500 sm:text-sm" id="price-currency">Km</span>
            </div>
          </div>
          <button type="button" id="btn${locationObj.id}"
                  class="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2
                         text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300
                         hover:bg-gray-50">
            ${saveBtnText.value}
          </button>
        </div>
      </div>
    `);

      if (!props.register && props.calc) {
        marker.bindPopup(popup, {
          minWidth: 100,
          maxWidth: 300,
          closeButton: true,
        });

        // When opening popup
        marker.on('popupopen', () => {
          // Set the current location safely using the actual object reference
          currentLocation.value = locationObj;

          // Using setTimeout instead of nextTick since we're outside Vue's rendering cycle
          setTimeout(() => {
            const inputField = document.getElementById('inputField' + locationObj.id);
            const saveButton = document.getElementById('btn' + locationObj.id);

            if (!inputField || !saveButton) {
              console.warn('Could not find popup elements');
              return;
            }

            // Set current radius value
            inputField.value = currentRadius.value || 0;

            // Update radius on input change
            inputField.addEventListener('input', (e) => {
              const newVal = +e.target.value;
              let rItem = formdata.value.radius.find(item => item.id === locationObj.id);
              if (rItem) {
                rItem.radius = newVal;
              } else {
                formdata.value.radius.push({
                  id: locationObj.id,
                  radius: newVal
                });
              }

              // Explicitly force redraw of circle
              // First remove existing circle
              if (locationObj.mapCircle && map.value) {
                try {
                  if (map.value.hasLayer(locationObj.mapCircle)) {
                    locationObj.mapCircle.removeFrom(map.value);
                  }
                } catch (e) {
                  console.warn(`Error removing circle from marker input handler: ${e.message}`);
                }
                locationObj.mapCircle = null;
              }

              // Then draw new circle
              drawCircle(locationObj);
            });

            // Close popup on save button click
            saveButton.addEventListener('click', () => {
              if (popup._source && popup._source.closePopup) {
                popup._source.closePopup();
              }
            });
          }, 10);
        });

        // When closing popup
        marker.on('popupclose', () => {
          reload.value = true;
          readyForSave.value = false;

          const asyncWrapper = async () => {
            try {
              let result = await recalculateArea(null);
              if (result === true) {
                reload.value = false;
                readyForSave.value = true;
                unsaved.value = true; // Mark as changed
              }
            } catch (error) {
              console.error('Error in recalc:', error);
              reload.value = false;
              readyForSave.value = true;
            }
          };

          asyncWrapper();
        });
      }
    } catch (e) {
      console.error(`Error drawing marker for location ID ${location.id}:`, e);
    }
  }

  // Draw a circle on the map for a location
  function drawCircle(location) {
    // Check if map exists before trying to use it
    if (!map.value) {
      console.warn('Cannot draw circle: map is not initialized');
      return;
    }

    try {
      // First, find the location in our cloned array to ensure we operate on the right object
      const locationIndex = locationsCloned.value.findIndex(loc => loc.id === location.id);
      if (locationIndex < 0) {
        console.warn(`Location with ID ${location.id} not found in locationsCloned`);
        return;
      }

      // Get reference to the actual stored location object (this is important)
      const locationObj = locationsCloned.value[locationIndex];

      // IMPORTANT: Remove old circle from the map if it exists
      if (locationObj.mapCircle) {
        try {
          // First try to remove it from the map if it's there
          if (map.value && map.value.hasLayer(locationObj.mapCircle)) {
            locationObj.mapCircle.removeFrom(map.value);
          }
        } catch (e) {
          console.warn(`Error removing circle from map: ${e.message}`);
        }

        // Explicitly delete the reference regardless of success
        locationObj.mapCircle = null;
      }

      // Only proceed with drawing if we need to (check calc flag or radiusFromDirectFilter)
      if (props.calc || props.radiusFromDirectFilter) {
        // Get radius in meters
        let radiusMeters = 0;

        if (props.radiusFromDirectFilter) {
          radiusMeters = props.radiusFromDirectFilter * 1000;
        } else {
          const radiusEntry = formdata.value.radius.find(item => item.id === locationObj.id);
          radiusMeters = (radiusEntry?.radius || 0) * 1000;
        }

        // Find corresponding postcode
        let currentPostcode = postcodes.value.find(obj => obj.id === locationObj.postcode_id);
        if (!currentPostcode) {
          console.warn(`Postcode not found for location ID: ${locationObj.id}`);
          return;
        }

        if (isNaN(radiusMeters)) {
          notification.error(t('companyArea.components.map.radiusErrorNotification'));
          return;
        }

        // Only add a circle if we have a valid radius greater than zero
        if (radiusMeters > 0) {
          // Create and add circle with explicit options
          const newCircle = L.circle(
            [currentPostcode.latitude, currentPostcode.longitude],
            {
              radius: radiusMeters,
              color: 'grey',
              weight: 1,
              fillColor: 'grey',
              fillOpacity: props.calc ? 0.2 : 0.3,
            }
          );

          // Explicitly add to map
          if (map.value) {
            newCircle.addTo(map.value);
            // Only assign the circle to the location object AFTER it's successfully added
            locationObj.mapCircle = newCircle;
          }
        } else {
        }
      }
    } catch (e) {
      console.error(`Error in drawCircle for location ID ${location.id}:`, e);
    }
  }

  function boundingBoxCheck(postcode, centerLat, centerLon, radiusKm) {
    const latRange = radiusKm / 111;
    const latMin = centerLat - latRange;
    const latMax = centerLat + latRange;

    const lonRange = radiusKm / (111 * Math.cos(centerLat * Math.PI / 180));
    const lonMin = centerLon - lonRange;
    const lonMax = centerLon + lonRange;

    return (
      postcode.latitude >= latMin &&
      postcode.latitude <= latMax &&
      postcode.longitude >= lonMin &&
      postcode.longitude <= lonMax
    );
  }

  function processInChunks(items, chunkSize, fn) {
    return new Promise((resolve) => {
      let index = 0;
      const length = items.length;

      const processChunk = () => {
        const end = Math.min(index + chunkSize, length);
        for (let i = index; i < end; i++) {
          fn(items[i]);
        }
        index = end;

        if (index < length) {
          setTimeout(processChunk, 0);
        } else {
          resolve();
        }
      };

      processChunk();
    });
  }

  async function recalculateArea(zip, init = false) {
    reload.value = true;
    // Check if map exists
    if (!map.value) {
      console.warn('Cannot recalculate area: map is not initialized');
      reload.value = false;
      return false;
    }

    if (!init) {
      unsaved.value = true;
    }

    // Clear out existing layers before recalculating
    for (const area in layer.value) {
      if (layer.value[area]) {
        try {
          layer.value[area].removeFrom(map.value);
        } catch (e) {
          console.warn(`Error removing layer ${area}:`, e);
        }
        delete layer.value[area];
      }
    }

    // Handle zip code toggle if provided
    if (zip) {
      readyForSave.value = false;

      // Toggle zip code selection
      if (activeZip.value?.includes(zip)) {
        activeZip.value = activeZip.value.filter(item => item !== zip);
      } else {
        if (!activeZip.value) {
          activeZip.value = [];
        }
        activeZip.value.push(zip);
        activeZip.value = activeZip.value.filter(item => item !== null);
      }

      // Clear and re-filter based on current activeZip
      zipPostcodes.value = [];
      zipPostcodes.value = postcodes.value.filter(pc =>
        activeZip.value?.some(button => pc.zip.startsWith(button)),
      );
    } else {
      // Radius calculation
      radiusPostcodes.value = [];
      const earthRadiusKm = 6371;

      for (const location of locationsCloned.value) {
        const locationRadius = formdata.value.radius.find(item => item.id === location.id)?.radius;
        if (isNaN(locationRadius)) {
          notification.error(t('companyArea.components.map.radiusErrorNotification'));
          reload.value = false;
          return false;
        }

        let currentPostcode = postcodes.value.find(obj => obj.id === location.postcode_id);
        if (!currentPostcode) continue;

        const centerLat = currentPostcode.latitude;
        const centerLon = currentPostcode.longitude;

        const checkDistance = (postcode) => {
          if (!boundingBoxCheck(postcode, centerLat, centerLon, locationRadius)) {
            return;
          }

          const dLat = deg2rad(postcode.latitude - centerLat);
          const dLon = deg2rad(postcode.longitude - centerLon);
          const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2rad(centerLat)) *
            Math.cos(deg2rad(postcode.latitude)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
          const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
          const distance = earthRadiusKm * c;

          if (distance <= locationRadius) {
            radiusPostcodes.value.push({
              ...postcode,
              location_id: location.id,
              distance: distance,
            });
          }
        };

        await processInChunks(postcodes.value, 1000, (pc) => {
          checkDistance(pc);
        });
      }
    }

    // Create a fresh combination of all postcodes
    let combinedPostcodes = [];
    // Use Set to avoid duplicates
    const uniqueZipPostcodes = new Set(zipPostcodes.value.map(p => p.id));
    const uniqueRadiusPostcodes = new Set(radiusPostcodes.value.map(p => p.id));

    // Combine both postcode types
    zipPostcodes.value.forEach(p => combinedPostcodes.push(p));
    radiusPostcodes.value.forEach(p => {
      if (!uniqueZipPostcodes.has(p.id)) {
        combinedPostcodes.push(p);
      }
    });

    try {
      // Process the postcodes - this will show progressive loading
      let result = await samplePostcodes(combinedPostcodes, true); // Force redraw

      // Make sure circles are above the areas
      locationsCloned.value.forEach(obj => {
        if (obj.mapCircle) {
          obj.mapCircle.bringToFront();
        }
      });

      reload.value = false;

      if (result === true) {
        if (zip) {
          readyForSave.value = true;
        }
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error('Error in recalculateArea:', e);
      reload.value = false;
      return false;
    }
  }

  async function samplePostcodes(companyPostcodesList, forceRedraw = false) {
    if (!map.value) {
      console.warn('Cannot sample postcodes: map is not initialized');
      return false;
    }

    try {
      // Create a new object for company area postcodes
      let companyAreaPostcodes = {};

      // Group postcodes by state_code
      for (let postcode of companyPostcodesList) {
        if (!companyAreaPostcodes.hasOwnProperty(postcode.state_code)) {
          companyAreaPostcodes[postcode.state_code] = [];
        }
        if (!companyAreaPostcodes[postcode.state_code].includes(postcode)) {
          companyAreaPostcodes[postcode.state_code].push(postcode);
        }
      }

      // Remove areas that no longer have postcodes
      for (const area in companyPostcodes.value) {
        if (!companyAreaPostcodes.hasOwnProperty(area) || companyAreaPostcodes[area].length === 0) {
          // Clear this area's data
          companyPostcodes.value[area] = [];

          // Remove the layer from the map
          if (layer.value[area]) {
            try {
              layer.value[area].removeFrom(map.value);
            } catch (e) {
              console.warn(`Error removing layer ${area}:`, e);
            }
            delete layer.value[area]; // Completely remove the reference
          }
        }
      }

      // Process each area
      let i = 0;
      for (const area in companyAreaPostcodes) {
        // If we need to update this area (new or changed)
        if (forceRedraw ||
          !companyPostcodes.value.hasOwnProperty(area) ||
          companyPostcodes.value[area].length !== companyAreaPostcodes[area].length) {

          // Update the postcodes data
          companyPostcodes.value[area] = companyAreaPostcodes[area];

          // Recalculate the choropleth data
          await calcChoroplethData(area, companyPostcodes.value[area]);
        }
        i++;
      }

      if (i === Object.keys(companyAreaPostcodes).length) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error('Error in samplePostcodes:', e);
      return false;
    }
  }

  async function calcChoroplethData(area, postcodesList) {
    if (!map.value) {
      console.warn('Cannot calculate choropleth data: map is not initialized');
      return false;
    }

    try {
      const geoJsonData = [];
      let geoJsonLocal = geoJson.value;

      if (geoJsonLocal.length > 0) {
        postcodesList.forEach((obj) => {
          if (geoJsonLocal.some(item => item.zip === obj.zip)) {
            geoJsonLocal
              .filter(item => item.zip === obj.zip)
              .forEach((geoItem) => {
                if (!geoJsonData.includes(geoItem)) {
                  geoJsonData.push(geoItem);
                }
              });
          }
        });
      }

      let data = [{
        type: 'FeatureCollection',
        features: [],
      }];

      geoJsonData.forEach((geoItem) => {
        try {
          data[0].features.push({
            type: 'Feature',
            properties: {
              id: geoItem.id,
              name: geoItem.name,
              zip: geoItem.zip,
              value: 2,
            },
            geometry: {
              type: 'Polygon',
              coordinates: JSON.parse(geoItem.geojson),
            },
          });
        } catch (e) {
          console.warn('Error parsing geojson for item:', geoItem, e);
        }
      });

      await updateChoroplethData(area, data);
      return true;
    } catch (e) {
      console.error('Error in calcChoroplethData:', e);
      return false;
    }
  }

  function getColor(value) {
    return value > 2
      ? '#8b233b'
      : value > 1
        ? '#8d3882'
        : value > 0.5
          ? '#bae4b3'
          : '#edf8e9';
  }

  function checkSpecificAreaSettings() {
    let radius = [];
    let zip = [];

    if (props.classification.radius) {
      radius = JSON.parse(JSON.stringify(props.classification.radius));
      zip = JSON.parse(JSON.stringify(props.classification.zip));
    } else {
      // Default Radius von allen Standorten
      locationsCloned.value.forEach(obj => {
        radius.push({ id: obj.id, radius: obj.radius });
      });
      zip = locationsCloned.value.find(obj => obj.headquarter === 1)?.zip;
    }

    formdata.value.radius = radius;
    activeZip.value = zip ? [...zip] : [];
  }

  async function updateChoroplethData(area, data) {
    if (!map.value) {
      console.warn('Cannot update choropleth data: map is not initialized');
      return false;
    }

    try {
      // Always remove existing layer for this area if it exists
      if (layer.value.hasOwnProperty(area) && layer.value[area]) {
        try {
          layer.value[area].removeFrom(map.value);
        } catch (e) {
          console.warn(`Error removing layer ${area}:`, e);
        }
        delete layer.value[area];
      }

      // Only create a new layer if we have features to display
      if (data[0].features.length > 0) {
        // Make this visible immediately by not awaiting
        layer.value[area] = L.geoJSON(data, {
          style: (feature) => {
            const value = feature.properties.value;
            return {
              fillColor: getColor(value),
              weight: 1,
              opacity: 0.4,
              color: 'white',
              fillOpacity: 0.5,
            };
          },
          onEachFeature: (feature, layer) => {
            layer.bindPopup(`Ort: ${feature.properties.name}<br>PLZ: ${feature.properties.zip}`);
          },
        }).addTo(map.value);

        // Small delay to allow UI to refresh between area renders
        await new Promise(resolve => setTimeout(resolve, 10));
      } else {
        // Ensure we have a null reference to indicate no layer for this area
        layer.value[area] = null;
      }

      return true;
    } catch (e) {
      console.error('Error in updateChoroplethData:', e);
      layer.value[area] = null;
      return false;
    }
  }

  watch(currentRadius, (newVal, oldVal) => {
    if (loaded.value && map.value && currentLocation.value) {
      // Explicitly clean all existing circles first
      if (currentLocation.value.id) {
        // Find the actual location object in locationsCloned
        const locationObj = locationsCloned.value.find(loc => loc.id === currentLocation.value.id);
        if (locationObj) {
          // Force circle removal and redraw for the current location
          // First, explicitly remove any existing circle
          if (locationObj.mapCircle) {
            try {
              if (map.value && map.value.hasLayer(locationObj.mapCircle)) {
                locationObj.mapCircle.removeFrom(map.value);
              }
            } catch (e) {
              console.warn(`Error removing circle during radius change: ${e.message}`);
            }
            locationObj.mapCircle = null;
          }

          // Then draw a new circle
          drawCircle(locationObj);
        }
      }
    }
  });

  watch(geoJson, (newValue, oldVal) => {
    if (!loaded.value && geoJson.value.length > 0 && postcodes.value.length > 0) {
      destroyMap();
      initMap();
      // Draw circles and markers
      if (map.value) {
        locationsCloned.value.forEach(obj => {
          drawCircle(obj);
          drawMarker(obj);
        });
      }
    }
  }, { deep: false });

  watch(precision, async (newValue, oldValue) => {
    try {
      const result = await geoJsonQuery.refetch();
      if (result.isSuccess && result.data) {
        geoJson.value = result.data[0];
      }

      zipPostcodes.value = [];
      radiusPostcodes.value = [];
      companyPostcodes.value = {};
      loaded.value = false;
      destroyMap();
      checkSpecificAreaSettings();
      await initMap();
      if (map.value) {
        locationsCloned.value.forEach(obj => {
          drawCircle(obj);
          drawMarker(obj);
        });
      }
    } catch (error) {
      console.error("Error updating precision:", error);
    }
  });

  watch(() => props.classification, async (newValue, oldValue) => {
    // Prevent processing if the classification didn't actually change
    if (newValue === oldValue) {
      console.log('Classification reference changed but content is the same, skipping reset');
      return;
    }

    // Use a complete reset function to ensure clean state
    await resetMapCompletely(false);

    // Update activeZip from the new classification (deep copy)
    activeZip.value = newValue.zip ? [...newValue.zip] : [];

    // Wait for next tick and refresh data
    await nextTick();
    refreshData();
  }, { deep: true, immediate: false });

  watch(() => props.radiusFromDirectFilter, () => {
    if (map.value) {
      locationsCloned.value.forEach(obj => {
        drawCircle(obj);
        drawMarker(obj);
      });
    }
  });

  watch(() => props.showRadiusPopupForLocation, (newValue, oldValue) => {
    if (newValue && map.value) {
      // Find the location with the matching ID
      const locationToShow = locationsCloned.value.find(loc => loc.id === newValue);

      if (locationToShow && locationToShow.mapMarker) {
        // Open the popup for this marker
        try {
          locationToShow.mapMarker.openPopup();

          // Set the current location for the popup to use
          currentLocation.value = locationToShow;

          // Ensure the map is centered on this location
          const currentPostcode = postcodes.value.find(obj => obj.id === locationToShow.postcode_id);
          if (currentPostcode && map.value) {
            map.value.setView(
              [currentPostcode.latitude, currentPostcode.longitude],
              map.value.getZoom(),
              { animate: true }
            );
          }
        } catch (e) {
          console.error(`Error opening popup for location ID ${newValue}:`, e);
        }
      } else {
        console.warn(`Location with ID ${newValue} not found or has no marker`);
      }
    }
  }, { immediate: true });


  function resetMapCompletely(preserveFormData = false) {
    // Reset all data structures
    zipPostcodes.value = [];
    radiusPostcodes.value = [];
    companyPostcodes.value = {};
    layer.value = {};
    loaded.value = false;
    reload.value = false;

    // Destroy the map instance
    destroyMap();

    // Generate a new unique ID for the map element
    mapElementId.value = `map-${Date.now()}-${Math.random().toString(36).substring(2, 9)}`;

    // Reset the locations to a fresh clone without any added properties
    locationsCloned.value = JSON.parse(JSON.stringify(props.locations));

    // Optionally reset form data
    if (!preserveFormData) {
      checkSpecificAreaSettings();
    }

    // Wait for DOM update before refreshing data
    return nextTick(() => {
      return refreshData();
    });
  }

  // Lifecycle hooks
  onMounted(() => {
    // Set default location to headquarters
    currentLocation.value = props.locations.find(item => item.headquarter === 1) || props.locations[0];

    // Make sure form data is initialized before loading the map
    if (locationsCloned.value.length > 0) {
      checkSpecificAreaSettings();
    }

    // Wait for next tick to ensure DOM is ready
    nextTick(() => {
      // Load initial data
      refreshData();
    });
  });

  onBeforeUnmount(() => {
    destroyMap();

    // Clear all data
    zipPostcodes.value = [];
    radiusPostcodes.value = [];
    companyPostcodes.value = {};
    layer.value = {};
    loaded.value = false;
  });
</script>
