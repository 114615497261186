<template>
<!--  <div class="text-center rounded mt-5 p-2 bg-green-400">-->
<!--    <span class="font-semibold">Günstige Umzugsboxen gesucht?</span> Kaufen Sie jetzt bis zum 28. Februar!-->
<!--    <div class="flex justify-center mt-4">-->
<!--      <button class="btn btn-md btn-primary" @click="$router.push({ name: 'CompanyShop' })">Zum Shop</button>-->
<!--    </div>-->
<!--  </div>-->
  <div v-if="status && $route().name !== 'RequiredActions'" class="text-center rounded mt-5 p-2" :class="{ 'bg-yellow-400':(company.status === 1), 'bg-red-600 text-white':(company.status !== 1)}">
    <p v-if="status === 'check_expired_lead'" v-html="$t('companyArea.accountStatus.checkExpiredLead')" />
    <p v-if="status === 'pay_commission'" v-html="$t('companyArea.accountStatus.payCommission')" />
    <p v-if="status === 'disabled'" v-html="$t('companyArea.accountStatus.disabled')" />
    <p v-if="status === 'credit_overdue'" v-html="$t('companyArea.accountStatus.creditOverdue')" />
    <p v-if="status === 'leads_overdue'" v-html="$t('companyArea.accountStatus.leadsOverdue')" />
    <p v-if="status === 'set_area'" v-html="$t('companyArea.accountStatus.setArea')" />
    <p v-if="status === 'setClassification'" v-html="$t('companyArea.accountStatus.setClassification')" />
    <p v-if="status === 'customerLanguages'" v-html="$t('companyArea.accountStatus.customerLanguages')" />
    <p v-if="status === 'noLeadsWanted'" v-html="$t('companyArea.accountStatus.break')" />
    <p v-if="status === 'unpaidInvoice'" v-html="$t('companyArea.accountStatus.unpaidInvoice')" />
    <p v-if="status === 'blockedMissingUID'" v-html="$t('companyArea.accountStatus.blockedMissingUID')" />
    <p v-if="status === 'blockedDeletedUID'" v-html="$t('companyArea.accountStatus.blockedDeletedUID')" />
    <p v-if="status === 'registerInvalid'" v-html="$t('companyArea.accountStatus.checkUid', {date: dayjsHelper.showDate(dayjsHelper.addDaysToDate(company.register_invalid_at ?? new Date(), 30))})" />
    <p v-if="status === 'setRegisterId'" v-html="$t('companyArea.accountStatus.enterUid', {date: dayjsHelper.showDate(dayjsHelper.addDaysToDate(company.register_invalid_at ?? new Date(), 30))})" />

    <div v-if="['check_expired_lead','leads_overdue'].includes(status)" class="flex justify-center mt-4">
      <button class="btn btn-md btn-primary" @click="$router.push({ name: 'RequiredActions' })">{{ $t('companyArea.accountStatus.zumOrdnerDringend') }}</button>
    </div>
    <div v-if="status === 'credit_overdue'" class="flex justify-center mt-4">
      <button class="btn btn-md btn-primary" @click="$router.push({ name: 'Credit' })">{{ $t('companyArea.accountStatus.guthabenVerwalten') }}</button>
    </div>
    <div v-if="status === 'setRegisterId' || status === 'registerInvalid' || status === 'blockedMissingUID' || status === 'blockedDeletedUID'"  class="flex justify-center mt-4">
      <button class="btn btn-md btn-primary" @click="$router.push({ name: 'Contact' })">{{ $t('general.next') }}</button>
    </div>
    <div v-if="status === 'set_conditions' && showModal === true">
      <confirmation-modal :content-size="true" size="big">
        <TermsOfService :company="company" mode="update" @next-step="showModal = false" />
      </confirmation-modal>
    </div>
  </div>
</template>

<script>
  import store from '../../store/mainStore';
  import { Translation as $route } from 'vue-i18n';
  import { createRouter as $router } from 'vue-router';
  import { dayjsHelper } from '../../../plugins/dayjsHelper';
  import TermsOfService from '@components/publicArea/registerCompany/steps/TermsOfService.vue';
  import ConfirmationModal from '@snippets/ConfirmationModal.vue';

  export default {
    name: 'AccountStatus',
    components: { ConfirmationModal, TermsOfService },
    props: {
      company: Object,
    },
    data() {
      return {
        showModal: false,
      };
    },
    computed: {
      companyLeads() {
        return store.state.company.leads.companyLeads;
      },
      status() {
        if(this.company.status === 1) {
          if (!this.company.conditions_accepted_at) {
            this.showModal = true;
            return 'set_conditions';
          }

          if(!this.checkCustomerLanguages()) {
            return 'customerLanguages'
          }
          if(!this.company.areaExists) {
            return 'set_area'
          }
          if(!this.company.classificationExists) {
            return 'setClassification'
          }
          if(!this.company.uid) {
            return 'setRegisterId'
          }
          if(this.company.register_invalid_at) {
            return 'registerInvalid'
          }
          if(this.company.no_leads_wanted === 1) {
            return 'noLeadsWanted';
          }
          let check = '';
          this.companyLeads.forEach(obj => obj.lead.required_actions.find(obj => obj.company_review_id !== null ? check = 'review' : ''));
          if(check === '') {
            this.companyLeads.forEach(obj => obj.lead.required_actions.find(obj => obj.duedate !== null ? check = 'expired' : ''));
          }
          if(check === '') {
            // if (this.company.assistant.toLowerCase() === 'ara'){
            //   return 'set_area';
            // }
            return null;
          } else if(check === 'review') {
            return 'pay_commission';
          } else {
            return 'check_expired_lead';
          }

        } else if(this.company.status === 2) {
          return 'disabled';
        } else if(this.company.status === 3) {
          return 'credit_overdue';
        } else if(this.company.status === 4) {
          return 'leads_overdue';
        } else if(this.company.status === 8) {
          return 'unpaidInvoice';
        } else if(this.company.status === 9) {
          return 'blockedMissingUID';
        } else if(this.company.status === 10 || this.company.status === 11 || this.company.status === 12) {
          return 'blockedDeletedUID';
        }
      },
    },
    watch: {
      companyLeads: {
        immediate: true,
        handler() {
          if(this.company.hasOwnProperty('id') && this.company.status !== 1) {
            store.dispatch('company/getCompany', this.company.id);
          }

        }, deep: true,
      },
    },
    methods: {
      $route() {
        return $route
      },
      checkCustomerLanguages() {
        if (!this.company.customer_languages) {
          return false;
        }
        for (let key in this.company.customer_languages) {
          if (this.company.customer_languages[key] === true) {
            return true;
          }
        }
        return false;
      }
    },
  };
</script>

<style scoped>

</style>
